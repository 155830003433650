import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { motion } from 'framer-motion';

// Define scrolling animation keyframes
const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

// Styled container with glassmorphism effect
const ScrollContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  padding: '24px 0',
  width: '100%',
  background: 'linear-gradient(90deg, rgba(10, 10, 15, 0.9) 0%, rgba(15, 23, 42, 0.9) 100%)',
  backdropFilter: 'blur(10px)',
  borderTop: '1px solid rgba(255, 255, 255, 0.06)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
  boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.3)',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '20%',
    height: '100%',
    background: 'linear-gradient(90deg, rgba(10, 10, 15, 0.9) 0%, rgba(10, 10, 15, 0) 100%)',
    zIndex: 2,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '20%',
    height: '100%',
    background: 'linear-gradient(270deg, rgba(15, 23, 42, 0.9) 0%, rgba(15, 23, 42, 0) 100%)',
    zIndex: 2,
  },
});

// Styled scrolling content
const ScrollingContent = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  animation: `${scroll} 40s linear infinite`,
  '&:hover': {
    animationPlayState: 'paused',
  },
});

// Styled word item
const WordItem = styled(motion.div)({
  display: 'inline-flex',
  alignItems: 'center',
  margin: '0 32px',
  position: 'relative',
});

// Styled separator
const Separator = styled(Box)({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  margin: '0 32px',
  background: 'linear-gradient(45deg, #00AAFF, #0047FF)',
  boxShadow: '0 0 10px rgba(0, 170, 255, 0.6)',
});

export default function ScrollingWords() {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const words = [
    'Embedded Systems',
    'Real-time Monitoring',
    'Performance Analytics',
    'System Diagnostics',
    'Debug Tools',
    'Development Solutions',
    'IoT Integration',
    'Edge Computing',
  ];

  // Duplicate the words to create seamless scrolling
  const duplicatedWords = [...words, ...words];

  // Word hover animation
  const wordVariants = {
    hover: {
      scale: 1.05,
      y: -3,
      color: '#00AAFF',
      transition: {
        duration: 0.2,
        ease: "easeOut"
      }
    }
  };

  // Adjust animation speed based on screen width
  useEffect(() => {
    const adjustSpeed = () => {
      if (contentRef.current) {
        // Calculate optimal animation duration based on content width
        const contentWidth = contentRef.current.scrollWidth;
        const viewportWidth = window.innerWidth;
        const speedFactor = contentWidth / viewportWidth * 20;

        // Set the animation duration
        contentRef.current.style.animationDuration = `${speedFactor}s`;
      }
    };

    adjustSpeed();
    window.addEventListener('resize', adjustSpeed);

    return () => {
      window.removeEventListener('resize', adjustSpeed);
    };
  }, []);

  return (
    <ScrollContainer ref={containerRef}>
      {/* Background glow effects */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '25%',
          width: '150px',
          height: '150px',
          background: 'radial-gradient(circle, rgba(0, 170, 255, 0.1) 0%, transparent 70%)',
          transform: 'translateY(-50%)',
          filter: 'blur(40px)',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          right: '20%',
          width: '100px',
          height: '100px',
          background: 'radial-gradient(circle, rgba(0, 71, 255, 0.1) 0%, transparent 70%)',
          transform: 'translateY(-50%)',
          filter: 'blur(40px)',
          zIndex: 1,
        }}
      />

      {/* Scrolling content */}
      <ScrollingContent ref={contentRef}>
        {duplicatedWords.map((word, index) => (
          <React.Fragment key={index}>
            <WordItem
              whileHover="hover"
              variants={wordVariants}
            >
              <Typography
                sx={{
                  fontSize: { xs: '18px', sm: '20px', md: '24px' },
                  fontWeight: 500,
                  color: 'white',
                  letterSpacing: '0.02em',
                  textShadow: '0 0 20px rgba(0, 170, 255, 0.3)',
                  transition: 'all 0.3s ease',
                }}
              >
                {word}
              </Typography>
            </WordItem>
            {index < duplicatedWords.length - 1 && <Separator />}
          </React.Fragment>
        ))}
      </ScrollingContent>

      {/* Grid overlay for tech effect */}
      <Box
        component={motion.div}
        animate={{
          backgroundPosition: ['0% 0%', '100% 100%'],
        }}
        transition={{ duration: 20, repeat: Infinity, ease: 'linear' }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.05,
          backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px),
                            linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px)`,
          backgroundSize: '20px 20px',
          zIndex: 3,
          pointerEvents: 'none',
        }}
      />
    </ScrollContainer>
  );
}
