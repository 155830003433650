import * as React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import ScrollingWords from './ScrollingWords';
import f2Image from '../media/f2.png';

// Keyframe animations
const moveInCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const moveVertical = keyframes`
  0% { transform: translateY(-50%); }
  50% { transform: translateY(50%); }
  100% { transform: translateY(-50%); }
`;

const moveHorizontal = keyframes`
  0% { transform: translateX(-50%) translateY(-10%); }
  50% { transform: translateX(50%) translateY(10%); }
  100% { transform: translateX(-50%) translateY(-10%); }
`;

// Styled components
const GradientBackground = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  overflow: 'hidden',
  background: 'linear-gradient(40deg, #000B2E, #0F2B4C)',
  top: 0,
  left: 0,
});

const GradientsContainer = styled('div')({
  filter: 'blur(40px)',
  width: '100%',
  height: '100%',
});

const GradientCircle = styled('div')(({ index }: { index: number }) => ({
  position: 'absolute',
  width: '80%',
  height: '80%',
  top: 'calc(50% - 40%)',
  left: 'calc(50% - 40%)',
  background: `radial-gradient(circle at center, 
    ${index === 1 ? 'rgba(0, 71, 255, 0.8)' : 
      index === 2 ? 'rgba(0, 150, 255, 0.8)' : 
      'rgba(0, 200, 255, 0.8)'} 0%, 
    rgba(0, 0, 0, 0) 50%)`,
  mixBlendMode: 'hard-light',
  opacity: 0.7,
  animation: `${index === 1 ? moveVertical : index === 2 ? moveInCircle : moveHorizontal} ${20 + index * 10}s ease infinite`,
}));

export default function Hero() {
  // Add mouse interaction ref from second script
  const interactiveRef = React.useRef<HTMLDivElement>(null);

  // Add mouse interaction effect from second script
  React.useEffect(() => {
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const move = () => {
      if (!interactiveRef.current) return;
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      interactiveRef.current.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      requestAnimationFrame(move);
    };

    const handleMouseMove = (event: MouseEvent) => {
      tgX = event.clientX;
      tgY = event.clientY;
    };

    window.addEventListener('mousemove', handleMouseMove);
    move();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Box
      id="hero"  // Added id here
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: { xs: '700px', sm: '800px' },
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Background Effects */}
      <GradientBackground>
        <GradientsContainer>
          {[1, 2, 3].map((i) => (
            <GradientCircle key={i} index={i} />
          ))}
          {/* Add mouse interactive element from second script */}
          <div
            ref={interactiveRef}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'radial-gradient(circle at center, rgba(64, 149, 255, 0.8) 0, rgba(0, 0, 0, 0) 50%)',
              mixBlendMode: 'hard-light',
              opacity: 0.7,
              top: '-50%',
              left: '-50%',
            }}
          />
        </GradientsContainer>
      </GradientBackground>

      {/* Hero Content */}
      <Box
        sx={{
          flex: 1, // Takes up the available space
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Container
          sx={{
            position: 'relative',
            zIndex: 2,
            maxWidth: '100% !important',
            textAlign: 'center',
          }}
        >
          <Stack spacing={3} alignItems="center" sx={{ mt: { xs: 8, sm: 10, md: 12 } }}>
            <Typography 
              variant="h1"
              sx={{ 
                fontSize: { xs: '2.9rem', sm: '3.2rem', md: '4rem' },
                lineHeight: 1.2,
                color: 'white',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              Advancing Embedded Technologies
            </Typography>
            <Typography
              sx={{
                color: 'white',
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                lineHeight: 1.6,
                maxWidth: { md: '70%' },
              }}
            >
              Orbbyt is developing observability technologies
              across the embedded development lifecycle.
            </Typography>
            <Box
              component="img"
              src={f2Image}
              alt="Technology visualization"
              sx={{
                width: { xs: '100%', md: '60%' },
                maxWidth: 1100,
                height: 'auto',
                objectFit: 'contain',
                mt: { xs: 4, md: 6 },
              }}
            />
          </Stack>
        </Container>
      </Box>

      {/* Scrolling Words Positioned at the Bottom */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 10,
        }}
      >
        <ScrollingWords />
      </Box>
    </Box>
  );
}