import { Box, Typography, Grid, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import DataFlowAnimation from './DataFlowAnimation';
import SensorsIcon from '@mui/icons-material/Sensors';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import BarChartIcon from '@mui/icons-material/BarChart';

// Enhanced accent colors
const accentColor = '#0EA5E9';
const secondaryAccent = '#7DD3FC';
const darkBackground = '#020617';

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 40, scale: 0.95 },
  visible: { 
    opacity: 1, 
    y: 0, 
    scale: 1,
    transition: { 
      duration: 0.6, 
      ease: [0.16, 1, 0.3, 1],
      type: "spring",
      stiffness: 100,
      damping: 20
    } 
  },
};

const HowItWorksSection = () => {

  return (
    <Box
      sx={{
        position: 'relative',
        background: `linear-gradient(195deg, #0f172a, ${darkBackground})`,
        py: { xs: 10, md: 18 },
        px: { xs: 2, md: 4, lg: 8 },
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '30%',
          right: '10%',
          width: '600px',
          height: '600px',
          background: `radial-gradient(circle, rgba(14, 165, 233, 0.1) 0%, transparent 70%)`,
          filter: 'blur(120px)',
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px), 
                            linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px)`,
          backgroundSize: '40px 40px',
          zIndex: 1,
          opacity: 0.5,
        }}
      />

      <Box
        sx={{
          maxWidth: '1800px',
          mx: 'auto',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Grid
          container
          spacing={{ xs: 6, md: 10 }}
          alignItems="center"
          justifyContent="center"
        >
          {/* Left Side: Information Panel */}
          <Grid item xs={12} md={6} lg={5}>
            <Box
              component={motion.div}
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.25 }}
              sx={{
                background: 'rgba(255,255,255,0.04)',
                backdropFilter: 'blur(20px)',
                borderRadius: 6,
                p: { xs: 4, md: 6 },
                border: '1px solid rgba(255,255,255,0.12)',
                boxShadow: '0 32px 64px -16px rgba(0,0,0,0.5)',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  borderRadius: 6,
                  padding: '1.5px',
                  background: `linear-gradient(120deg, ${accentColor}40, rgba(255,255,255,0.01))`,
                  WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                  mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                  WebkitMaskComposite: 'xor',
                  maskComposite: 'exclude',
                  zIndex: -1,
                },
              }}
            >
              <Stack spacing={{ xs: 4, md: 5 }}>
                <motion.div variants={textVariants}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 800,
                      background: `linear-gradient(45deg, ${secondaryAccent}, ${accentColor} 30%, #0284c7)`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      letterSpacing: '-0.03em',
                      mb: 3,
                      fontSize: { xs: '2.25rem', md: '3rem' },
                      lineHeight: 1.1,
                      textShadow: `0 0 15px ${accentColor}40`,
                    }}
                  >
                    How Orbbyt Works
                  </Typography>
                </motion.div>

                {[
                  { 
                    icon: <SensorsIcon fontSize="inherit" sx={{ color: 'white' }} />, 
                    title: 'Sensors & Actuators', 
                    content: 'Connect and manage IoT devices for real-time data collection and control across your entire infrastructure.' 
                  },
                  { 
                    icon: <DeveloperBoardIcon fontSize="inherit" sx={{ color: 'white' }} />, 
                    title: 'Microcontroller', 
                    content: 'Edge processing for real-time data analysis with low latency responses to critical events.' 
                  },
                  { 
                    icon: <CloudQueueIcon fontSize="inherit" sx={{ color: 'white' }} />, 
                    title: 'Orbbyt Platform', 
                    content: 'Cloud-based system that receives, processes, and stores data for advanced analysis.' 
                  },
                  { 
                    icon: <BarChartIcon fontSize="inherit" sx={{ color: 'white' }} />, 
                    title: 'Visualization & Analysis', 
                    content: 'Intuitive visual interface for exploring trends, identifying anomalies, and extracting actionable intelligence.' 
                  },
                ].map((feature, index) => (
                  <motion.div key={index} variants={textVariants}>
                    <Box
                      sx={{
                        background: 'rgba(255,255,255,0.03)',
                        borderRadius: 3,
                        p: 3.5,
                        border: '1px solid rgba(255,255,255,0.08)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          background: 'rgba(255,255,255,0.06)',
                          boxShadow: `0 12px 28px -6px rgba(0,0,0,0.3), 0 0 10px 2px ${accentColor}20`,
                          transform: 'translateY(-4px) translateX(2px)',
                          borderLeft: `4px solid ${accentColor}`,
                        },
                      }}
                    >
                      <Stack direction="row" spacing={3} alignItems="flex-start">
                        <Box
                          sx={{
                            background: `linear-gradient(45deg, ${accentColor}, ${secondaryAccent})`,
                            width: 50,
                            height: 50,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 26,
                            flexShrink: 0,
                            boxShadow: `0 6px 12px -2px ${accentColor}40`,
                          }}
                        >
                          {feature.icon}
                        </Box>
                        <Box>
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              color: 'white', 
                              fontWeight: 700, 
                              mb: 1,
                              fontSize: '1.25rem',
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography 
                            variant="body1" 
                            sx={{ 
                              color: 'rgba(255,255,255,0.85)',
                              lineHeight: 1.6,
                              fontSize: '1rem',
                            }}
                          >
                            {feature.content}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </motion.div>
                ))}
              </Stack>
            </Box>
          </Grid>

          {/* Right Side: Animation Panel */}
          <Grid item xs={12} md={6} lg={7}>
            <Box
              component={motion.div}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              sx={{
                position: 'relative',
                width: '100%',
                minHeight: { xs: 350, md: 600 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: 6,
                border: '1px solid rgba(255,255,255,0.12)',
                boxShadow: '0 32px 64px -16px rgba(0,0,0,0.5)',
                background: 'linear-gradient(145deg, rgba(255,255,255,0.06), rgba(255,255,255,0.01))',
                aspectRatio: { xs: '4/3', md: '16/9' },
                backdropFilter: 'blur(10px)',
                transform: { xs: 'none', md: 'perspective(1000px) rotateY(5deg)' },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 20,
                  left: 20,
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  p: 1.5,
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  zIndex: 10,
                }}
              >
                <Box sx={{
                  width: '10px',
                  height: '10px',
                  bgcolor: accentColor,
                  borderRadius: '50%',
                  mr: 1.5,
                  animation: 'pulse 1.5s infinite',
                  '@keyframes pulse': {
                    '0%': { boxShadow: `0 0 0 0 ${accentColor}90` },
                    '70%': { boxShadow: `0 0 0 10px ${accentColor}00` },
                    '100%': { boxShadow: `0 0 0 0 ${accentColor}00` },
                  },
                }} />
                <Typography variant="caption" sx={{ color: accentColor, fontWeight: 600, letterSpacing: '0.05em' }}>
                  DATA FLOW VISUALIZATION
                </Typography>
              </Box>
              
              <DataFlowAnimation />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HowItWorksSection;
