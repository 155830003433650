import logo from '../../media/Logowhite.png'; // Replace with your actual logo file
import twitterIcon from '../../media/twitter-icon.png'; // Replace with your Twitter icon file
import linkedinIcon from '../../media/linkedin-icon.png'; // Replace with your LinkedIn icon file
import './Footer.css';
import { keyframes, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Add keyframe animations
const moveInCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const moveHorizontal = keyframes`
  0% { transform: translateX(-50%) translateY(-10%); }
  50% { transform: translateX(50%) translateY(10%); }
  100% { transform: translateX(-50%) translateY(-10%); }
`;

// Styled components
const GradientBackground = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  overflow: 'hidden',
  background: 'linear-gradient(40deg, #000B2E, #0F2B4C)',
  top: 0,
  left: 0,
});

const GradientsContainer = styled('div')({
  filter: 'blur(40px)',
  width: '100%',
  height: '100%',
});

const GradientCircle = styled('div')(({ index }: { index: number }) => ({
  position: 'absolute',
  width: '80%',
  height: '80%',
  top: 'calc(50% - 40%)',
  left: 'calc(50% - 40%)',
  background: `radial-gradient(circle at center, 
    ${index === 1 ? 'rgba(0, 71, 255, 0.5)' : 'rgba(0, 150, 255, 0.5)'} 0%, 
    rgba(0, 0, 0, 0) 50%)`,
  mixBlendMode: 'hard-light',
  opacity: 0.5,
  animation: `${index === 1 ? moveInCircle : moveHorizontal} ${20 + index * 10}s ease infinite`,
}));

const Footer: React.FC = () => {
    return (
        <Box sx={{ 
            bgcolor: '#020617',
            mt: 'auto',
            display: 'flex',          // Added for centering
            justifyContent: 'center', // Added for centering
            width: '100%'
        }}>
            <footer className="footer" style={{ 
                position: 'relative', 
                overflow: 'hidden',
                borderTopLeftRadius: '36px',
                borderTopRightRadius: '36px',
                backgroundColor: '#000B2E',
                width: '100%',         // Reduced width to 90%
                maxWidth: '1800px'    // Optional: add max-width if needed
            }}>
                <GradientBackground sx={{
                    borderTopLeftRadius: '36px',
                    borderTopRightRadius: '36px',
                }}>
                    <GradientsContainer>
                        {[1, 2].map((i) => (
                            <GradientCircle key={i} index={i} />
                        ))}
                    </GradientsContainer>
                </GradientBackground>
                
                <div className="footer-content" style={{ position: 'relative', zIndex: 1 }}>
                    <div className="footer-left">
                        <p>&copy; 2024 Orbbyt.</p>
                    </div>
                    <img src={logo} alt="Orbbyt Logo" className="footer-logo" />
                    <div className="footer-right">
                        <a href="https://x.com/0rbbyt" target="_blank" rel="noopener noreferrer">
                            <img src={twitterIcon} alt="Twitter Icon" className="social-icon" />
                        </a>
                        <a href="https://www.linkedin.com/company/orbbyt" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn Icon" className="social-icon" />
                        </a>
                    </div>
                </div>
            </footer>
        </Box>
    );
}

export default Footer;
