import { motion } from 'framer-motion';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import logoWhite from '../media/Logowhite.png';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt'; // For Sensors
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi'; // For Actuators
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'; // For Microcontroller
import BarChartIcon from '@mui/icons-material/BarChart'; // For Visualization
import TimelineIcon from '@mui/icons-material/Timeline'; // For Analytics

interface Particle {
  id: number;
  x: number;
  y: number;
  size: number;
  color: string;
  opacity: number;
}

interface Connection {
  from: string;
  to: string;
  pulseColor: string;
}

interface Node {
  id: string;
  x: number;
  y: number;
  label: string;
  icon: JSX.Element; // Ensure icon is always a JSX.Element
  type: 'input' | 'processor' | 'output';
}

const DataFlowAnimation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const activeLines: Record<string, boolean> = {
    'sensors-microcontroller': true,
    'actuators-microcontroller': true,
    'microcontroller-orbbyt': true,
    'orbbyt-visualization': true,
    'orbbyt-analytics': true
  };

  const scaleFactor = isMobile ? 0.85 : 1;
  const xOffset = isMobile ? 55 : 0;
  const yOffset = isMobile ? -10 : 0;

  const scaleX = (x: number) => x * scaleFactor - xOffset;
  const scaleY = (y: number) => y * scaleFactor - yOffset;

  const nodes: Node[] = [
    { id: 'sensors', x: 120, y: 100, label: 'Sensors', icon: <SatelliteAltIcon sx={{ color: 'white' }} />, type: 'input' },
    { id: 'actuators', x: 120, y: 180, label: 'Actuators', icon: <SettingsInputHdmiIcon sx={{ color: 'white' }} />, type: 'input' },
    { id: 'microcontroller', x: 320, y: 140, label: 'Microcontroller', icon: <DeveloperBoardIcon sx={{ color: 'white' }} />, type: 'processor' }, // Updated icon
    { id: 'orbbyt', x: 520, y: 140, label: 'Orbbyt Core', icon: <></>, type: 'processor' }, // Use an empty fragment or placeholder
    { id: 'visualization', x: 720, y: 80, label: 'Visualization', icon: <BarChartIcon sx={{ color: 'white' }} />, type: 'output' },
    { id: 'analytics', x: 720, y: 200, label: 'Analytics', icon: <TimelineIcon sx={{ color: 'white' }} />, type: 'output' },
  ];

  const connections: Connection[] = [
    { from: 'sensors', to: 'microcontroller', pulseColor: '#60a5fa' },
    { from: 'actuators', to: 'microcontroller', pulseColor: '#60a5fa' },
    { from: 'microcontroller', to: 'orbbyt', pulseColor: '#60a5fa' },
    { from: 'orbbyt', to: 'visualization', pulseColor: '#60a5fa' },
    { from: 'orbbyt', to: 'analytics', pulseColor: '#60a5fa' },
  ];

  const particles: Particle[] = Array.from({ length: 20 }, (_, i) => ({
    id: i,
    x: Math.random() * 800,
    y: Math.random() * 300,
    size: Math.random() * 1.5 + 0.5,
    color: Math.random() > 0.7 ? '#60a5fa' : '#ffffff',
    opacity: Math.random() * 0.5 + 0.1
  }));

  const getNodeStyle = (type: string) => {
    const styles = {
      input: {
        bg: 'linear-gradient(135deg, #0b4b75, #166da8)',
        filter: 'drop-shadow(0 0 8px rgba(96,165,250,0.5))'
      },
      processor: {
        bg: 'linear-gradient(135deg, #166da8, #2196f3)',
        filter: 'drop-shadow(0 0 10px rgba(96,165,250,0.6))'
      },
      output: {
        bg: 'linear-gradient(135deg, #0b4b75, #166da8)',
        filter: 'drop-shadow(0 0 8px rgba(96,165,250,0.5))'
      }
    };
    return styles[type as keyof typeof styles] || styles.input;
  };

  const generatePath = (fromNode: string, toNode: string): string => {
    const from = nodes.find((n) => n.id === fromNode);
    const to = nodes.find((n) => n.id === toNode);
    if (!from || !to) return '';
    const fromX = scaleX(from.x);
    const fromY = scaleY(from.y);
    const toX = scaleX(to.x);
    const toY = scaleY(to.y);
    const midX = (fromX + toX) / 2;
    const controlPointOffset = Math.abs(fromY - toY) * 0.8;
    return `M${fromX},${fromY} C${midX + controlPointOffset},${fromY} ${midX - controlPointOffset},${toY} ${toX},${toY}`;
  };

  const imageScale = 0.8;

  return (
    <Box
      sx={{
        position: 'relative',
        background: 'linear-gradient(195deg, #0a0a0f, #0f172a)',
        borderRadius: 4,
        p: { xs: 2, md: 4 },
        border: '1px solid rgba(255,255,255,0.08)',
        boxShadow: '0 24px 48px -12px rgba(0,0,0,0.4)',
        overflow: 'hidden',
        width: '80%',
        height: '80%',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-20%',
          left: '-10%',
          width: '600px',
          height: '600px',
          background: 'radial-gradient(circle, rgba(96,165,250,0.15) 0%, transparent 70%)',
          filter: 'blur(80px)',
          zIndex: 1,
        },
      }}
    >
      <Box
        component={motion.div}
        animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
        transition={{ duration: 20, repeat: Infinity, ease: 'linear' }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.15,
          backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px),
                            linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px)`,
          backgroundSize: '24px 24px',
          zIndex: 1,
        }}
      />

      <Box sx={{ position: 'relative', zIndex: 2, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <svg 
          width={isMobile ? "100%" : "800"} 
          height={isMobile ? "300" : "300"} 
          viewBox={isMobile ? "0 0 600 300" : "0 0 800 300"}
          preserveAspectRatio="xMidYMid meet"
          style={{ filter: 'contrast(1.1) saturate(1.2)' }}
        >
          {particles.map((particle) => (
            <circle
              key={`particle-${particle.id}`}
              cx={isMobile ? particle.x * scaleFactor : particle.x}
              cy={isMobile ? particle.y * scaleFactor : particle.y}
              r={particle.size}
              fill={particle.color}
              opacity={particle.opacity}
            />
          ))}

          {connections.map((conn) => {
            const isActive = activeLines[`${conn.from}-${conn.to}`];
            const pathString = generatePath(conn.from, conn.to);
            const key = `${conn.from}-${conn.to}`;
            const pathId = `path-${conn.from}-${conn.to}`;

            return (
              <g key={key}>
                <path id={pathId} d={pathString} stroke="#333" strokeWidth="2" fill="none" />
                {isActive && (
                  <>
                    <path
                      d={pathString}
                      stroke="url(#blueGradient)"
                      strokeWidth="2"
                      fill="none"
                      strokeDasharray="5,3"
                      filter="url(#glow)"
                    >
                      <animate attributeName="stroke-dashoffset" from="8" to="0" dur="1s" repeatCount="indefinite" />
                    </path>
                    {[...Array(3)].map((_, idx) => (
                      <circle key={idx} r="3" fill={conn.pulseColor} filter="url(#glow)">
                        <animateMotion
                          path={pathString}
                          dur={`${1.2 + idx * 0.2}s`}
                          begin={`${idx * 0.3}s`}
                          repeatCount="indefinite"
                          rotate="auto"
                        />
                      </circle>
                    ))}
                  </>
                )}
              </g>
            );
          })}

          {nodes.map((node) => {
            const style = getNodeStyle(node.type);
            const nodeSize = (node.type === 'processor' ? 60 : 50) * (isMobile ? 0.7 : 1);
            const nodeX = scaleX(node.x);
            const nodeY = scaleY(node.y);

            return (
              <g key={node.id} style={{ transition: 'all 0.3s ease' }}>
                {node.id === 'orbbyt' && (
                  <circle cx={nodeX} cy={nodeY} fill="none" stroke="#60a5fa" strokeWidth="2" opacity="0.6">
                    <animate attributeName="r" from={nodeSize * 0.6} to={nodeSize * 1.5} dur="2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" from="0.6" to="0" dur="2s" repeatCount="indefinite" />
                  </circle>
                )}

                {node.id === 'orbbyt' ? (
                  <>
                    <defs>
                      <clipPath id={`clip-${node.id}`}>
                        <polygon 
                          points={`${nodeX},${nodeY - nodeSize / 2} ${nodeX + nodeSize / 2},${nodeY} ${nodeX},${nodeY + nodeSize / 2} ${nodeX - nodeSize / 2},${nodeY}`} 
                        />
                      </clipPath>
                    </defs>
                    <polygon
                      points={`${nodeX},${nodeY - nodeSize / 2} ${nodeX + nodeSize / 2},${nodeY} ${nodeX},${nodeY + nodeSize / 2} ${nodeX - nodeSize / 2},${nodeY}`}
                      fill={style.bg}
                      style={{ filter: style.filter }}
                      stroke="#60a5fa"
                      strokeWidth="2"
                    />
                    <image
                      href={logoWhite}
                      x={nodeX - (nodeSize * imageScale) / 2}
                      y={nodeY - (nodeSize * imageScale) / 2}
                      width={nodeSize * imageScale}
                      height={nodeSize * imageScale}
                      preserveAspectRatio="xMidYMid meet"
                      clipPath={`url(#clip-${node.id})`}
                    />
                  </>
                ) : (
                  <>
                    {node.type === 'processor' ? (
                      <polygon
                        points={`${nodeX},${nodeY - nodeSize / 2} ${nodeX + nodeSize / 2},${nodeY} ${nodeX},${nodeY + nodeSize / 2} ${nodeX - nodeSize / 2},${nodeY}`}
                        fill={style.bg}
                        style={{ filter: style.filter }}
                        stroke="#60a5fa"
                        strokeWidth="2"
                      />
                    ) : (
                      <rect
                        x={nodeX - nodeSize / 2}
                        y={nodeY - nodeSize / 2}
                        width={nodeSize}
                        height={nodeSize}
                        rx="6"
                        fill={style.bg}
                        style={{ filter: style.filter }}
                        stroke="#60a5fa"
                        strokeWidth="2"
                      />
                    )}
                    <foreignObject x={nodeX - nodeSize / 4} y={nodeY - nodeSize / 4} width={nodeSize / 2} height={nodeSize / 2}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        {node.icon}
                      </Box>
                    </foreignObject>
                  </>
                )}

                <text
                  x={nodeX}
                  y={nodeY + nodeSize / 2 + 15}
                  textAnchor="middle"
                  fill="white"
                  fontSize="10"
                  opacity="0.8"
                >
                  {node.label}
                </text>
              </g>
            );
          })}
          
          <defs>
            <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#60a5fa" stopOpacity="1" />
              <stop offset="100%" stopColor="#3b82f6" stopOpacity="1" />
            </linearGradient>
            <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur stdDeviation="2" result="blur" />
              <feComposite in="SourceGraphic" in2="blur" operator="over" />
            </filter>
          </defs>
        </svg>
      </Box>
    </Box>
  );
};

export default DataFlowAnimation;