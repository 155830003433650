import { Box, Container, Typography, Button } from '@mui/material';
import { Bolt, Terminal, Insights, SettingsInputAntenna } from '@mui/icons-material';
import { motion } from 'framer-motion';
import f8Image from '../media/f9.png';

const accentColor = '#0099FF'; // Cyber blue accent color

// Create a motion-enhanced version of the MUI Button
const MotionButton = motion(Button);

export default function OrbbytDashboard() {
  return (
    <Box 
      id="orbbyt-dashboard"
      sx={{ 
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 4, sm: 8, md: 12 },
        minHeight: '600px',
        background: 'linear-gradient(195deg, #0f172a, #0f172a)',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-20%',
          left: '-10%',
          width: '600px',
          height: '600px',
          background: 'radial-gradient(circle, rgba(96, 165, 250, 0.15) 0%, transparent 70%)',
          filter: 'blur(80px)',
          zIndex: 1,
        },
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          position: 'relative',
          zIndex: 2,
          pl: { xs: 2, sm: 4, md: 8 },
          pr: { xs: 2, sm: 4, md: 8 },
          maxWidth: '100% !important',
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 4,
        }}
      >
        {/* Image Section */}
        <Box 
          component={motion.div}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          sx={{
            width: { xs: '100%', md: '60%' },
            position: 'relative',
            order: { xs: 1, md: 1 },
            // Remove this padding right that's creating the gap
            // pr: { md: 4 },
            borderRadius: '16px',
            overflow: 'hidden',
            border: '1px solid rgba(255,255,255,0.08)',
            boxShadow: '0 24px 48px -12px rgba(0,0,0,0.4)',
          }}
        >
          <img
            src={f8Image}
            alt="Orbbyt Dashboard"
            style={{
              width: '100%',
              height: 'auto',
              display: 'block', // This prevents the default inline behavior that creates bottom space
              borderRadius: '8px',
              boxShadow: `0 0 40px ${accentColor}33`,
              verticalAlign: 'bottom', // This helps eliminate any remaining gap
            }}
          />
          <Box sx={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            p: 1.5,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <Box sx={{
              width: '8px',
              height: '8px',
              bgcolor: accentColor,
              borderRadius: '50%',
              mr: 1,
              animation: 'pulse 1.5s infinite',
              '@keyframes pulse': {
                '0%': { boxShadow: `0 0 0 0 ${accentColor}80` },
                '70%': { boxShadow: `0 0 0 8px ${accentColor}00` },
                '100%': { boxShadow: `0 0 0 0 ${accentColor}00` },
              },
            }} />
            <Typography variant="caption" sx={{ color: accentColor }}>
              LIVE TELEMETRY STREAM
            </Typography>
          </Box>
        </Box>

        {/* Content Section */}
        <Box 
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          sx={{ 
            width: { xs: '90%', md: '50%' },
            order: { xs: 2, md: 2 },
            position: 'relative',
            background: 'linear-gradient(145deg, rgba(255,255,255,0.05), rgba(255,255,255,0.01))',
            borderRadius: 4,
            p: { xs: 3, md: 5 },
            border: '1px solid rgba(255,255,255,0.08)',
            boxShadow: '0 24px 48px -12px rgba(0,0,0,0.4)',
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: 0,
              borderRadius: 4,
              padding: '1px',
              background: 'linear-gradient(120deg, rgba(255,255,255,0.15), rgba(255,255,255,0.01))',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              zIndex: -1,
            },
          }}
        >
          <Box component={motion.div} whileHover={{ y: -4 }} transition={{ type: "spring", stiffness: 100 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Bolt sx={{ color: accentColor, fontSize: '2rem', mr: 1.5 }} />
              <Typography
                variant="h2"
                sx={{
                  color: 'white',
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                  textShadow: `0 0 10px ${accentColor}33`,
                  background: 'linear-gradient(45deg, #7DD3FC, #38BDF8 30%, #0EA5E9)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '-0.03em',
                }}
              >
                Next-Gen Embedded Observability
              </Typography>
            </Box>
          </Box>

          <Box component={motion.div} whileHover={{ y: -4 }} transition={{ type: "spring", stiffness: 100, delay: 0.1 }}>
            <Typography
              variant="h3"
              sx={{
                color: accentColor,
                mb: 3,
                fontSize: { xs: '1.25rem', md: '1.5rem' },
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <Terminal fontSize="small" />
              Beyond Serial Terminals. Beyond Basic Telemetry.
            </Typography>
          </Box>

          <Box 
            component={motion.div} 
            whileHover={{ y: -4 }} 
            transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
            sx={{
              borderLeft: `3px solid ${accentColor}`,
              pl: 3,
              mb: 4,
              background: 'rgba(255,255,255,0.02)',
              borderRadius: 2,
              p: 3,
              border: '1px solid rgba(255,255,255,0.06)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                background: 'rgba(255,255,255,0.04)',
                boxShadow: '0 8px 24px -4px rgba(0,0,0,0.3)',
              },
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: { xs: '1rem', md: '1.1rem' },
                lineHeight: 1.6,
                mb: 2,
              }}
            >
              Transform your embedded workflow with millisecond-resolution monitoring, 
              collaborative debugging tools, and predictive analytics for IoT ecosystems.
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mt: 2 }}>
              {['Developers', 'Manufacturers', "Clients"].map((role) => (
                <Box key={role} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SettingsInputAntenna sx={{ color: accentColor, fontSize: '1rem' }} />
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {role}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box component={motion.div} whileHover={{ y: -4 }} transition={{ type: "spring", stiffness: 100, delay: 0.3 }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <MotionButton
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                variant="contained"
                startIcon={<Insights />}
                sx={{
                  background: 'linear-gradient(-45deg, #0047FF, #0096FF, #0090b7)',
                  color: 'white',
                  '&:hover': {
                    opacity: 0.9,
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.3s ease',
                  fontWeight: 'bold',
                  px: 4,
                  py: 1.5,
                }}
                href="https://dashboard.orbbyt.com"
              >
                Launch Dashboard
              </MotionButton>
              <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                Real-time ◆ Multi-protocol ◆ E2E Encrypted
              </Typography>
            </Box>
          </Box>
          
          <Box 
            component={motion.div} 
            whileHover={{ y: -4 }} 
            transition={{ type: "spring", stiffness: 100, delay: 0.4 }}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 2,
              mt: 4,
              borderTop: `1px solid rgba(255,255,255,0.1)`,
              pt: 3,
              justifyItems: 'center',
              textAlign: 'center',
            }}
          >
            {[
              { value: '10ms', label: 'Resolution' },
              { value: '∞ Devices', label: 'Scalability' },
              { value: '24/7', label: 'Monitoring' },
            ].map((metric) => (
              <Box key={metric.label} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography 
                  variant="h4" 
                  sx={{ 
                    fontWeight: 'bold',
                    background: 'linear-gradient(45deg, #7DD3FC, #38BDF8 30%, #0EA5E9)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {metric.value}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  {metric.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
