import { Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer/Footer';
import OrbbytDashboard from './components/OrbbytDashboard';
import HowItWorksSection from './components/HowItWorksSection';

import './App.css';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={
          <>
            <AppAppBar />
            <Hero />
            <OrbbytDashboard />
            <HowItWorksSection />
            <Footer />
          </>
        } />
      </Routes>
    </div>
  );
}

export default App;
